<template>
  <div class="search-view">
    <div class="safety-wraper">
      <div class="search-filter-group">
        <div class="search-top">
          <div class="search-sort">
            <span>排序:</span>
            <radio-list
              :value="form.sortby"
              :list="sortList"
              theme-type="text"
              @change="handleSortChange"
            ></radio-list>
          </div>
          <div class="search-input-group">
            <input
              type="text"
              v-model="form.name"
              placeholder="请输入公司名称"
            />
            <button @click="getList(1)">搜索</button>
          </div>
        </div>

        <div class="search-filter-content">
          <div class="search-filter-item">
            <span class="filter-label">信息系统集成及服务资质：</span>
            <radio-list
              :value="form.sysIntegration"
              :list="sysIntegrationList"
              theme-type="box"
              @change="handleChange"
            ></radio-list>
          </div>

          <div class="search-filter-item">
            <span class="filter-label">建筑智能化系统设计专项：</span>
            <radio-list
              :value="form.buildingIntelligent"
              :list="buildingIntelligentList"
              theme-type="box"
              @change="handleChange"
            ></radio-list>
          </div>

          <div class="search-filter-item">
            <span class="filter-label">电子与智能化工程专业承包：</span>
            <radio-list
              :value="form.electronicIntelligent"
              :list="electronicIntelligentList"
              theme-type="box"
              @change="handleChange"
            ></radio-list>
          </div>

          <div class="search-filter-item">
            <span class="filter-label">高新技术企业：</span>
            <radio-list
              :value="form.tech"
              :list="techList"
              theme-type="box"
              @change="handleChange"
            ></radio-list>
          </div>

          <div class="search-filter-item">
            <span class="filter-label">安全技术防范行业资质：</span>
            <radio-list
              :value="form.antiSafe"
              :list="antiSafeList"
              theme-type="box"
              @change="handleChange"
            ></radio-list>
          </div>

          <div class="search-filter-item mutiply-select-row">
            <span class="filter-label">多项选择：</span>
            <mutiply-list
              :value="form.otherList"
              :list="otherList"
              theme-type="box"
              @change="handleChangeOther"
            ></mutiply-list>
          </div>
        </div>
      </div>

      <div class="search-result-group">
        <div class="search-result-item" v-for="item in list" :key="item.id">
          <div class="search-result-image">
            <img :src="item.logo" alt="" />
          </div>
          <div class="search-result-content">
            <div class="search-result-title">{{ item.name }}</div>
            <div class="search-result-count" v-show="item.bidAmount">
              年投标规模 {{ item.bidAmount | currency }} 万
            </div>
            <div class="search-result-sales" v-show="item.salse">
              年销售额 {{ item.salse | currency }} 万
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="page.pageNum"
          background
          layout="prev, pager, next"
          :total="page.total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import RadioList from "@/components/radio-list.vue";
import MutiplyList from "@/components/mutiply-list.vue";

export default {
  components: {
    RadioList,
    MutiplyList,
  },
  data() {
    return {
      sortList: [
        { key: "createTime", value: "默认" },
        { key: "bidAmount", value: "年投标规模" },
        { key: "salse", value: "年销售额" },
      ],
      sysIntegrationList: [
        { key: "一级", value: "一级", title: "sysIntegration" },
        { key: "二级", value: "二级", title: "sysIntegration" },
        { key: "三级", value: "三级", title: "sysIntegration" },
        { key: "无", value: "无", title: "sysIntegration" },
      ],
      buildingIntelligentList: [
        { key: "甲级", value: "甲级", title: "buildingIntelligent" },
        { key: "乙级", value: "乙级", title: "buildingIntelligent" },
        { key: "无", value: "无", title: "buildingIntelligent" },
      ],
      electronicIntelligentList: [
        { key: "一级", value: "一级", title: "electronicIntelligent" },
        { key: "二级", value: "二级", title: "electronicIntelligent" },
        { key: "无", value: "无", title: "electronicIntelligent" },
      ],
      techList: [
        { key: "国家高新", value: "国家高新技术企业", title: "tech" },
        { key: "省高新", value: "省高新技术企业", title: "tech" },
        { key: "无", value: "无", title: "tech" },
      ],

      antiSafeList: [
        { key: "一级", value: "一级", title: "antiSafe" },
        { key: "二级", value: "二级", title: "antiSafe" },
        { key: "三级", value: "三级", title: "antiSafe" },
        { key: "无", value: "无", title: "antiSafe" },
      ],
      otherList: [
        { key: 0, value: "涉密系统集成资质" },
        { key: 1, value: "双软企业" },
        { key: 2, value: "CMMI" },
        { key: 3, value: "ITSS" },
        { key: 4, value: "CS" },
        { key: 5, value: "ISO" },
      ],

      form: {
        name: "",
        sortby: 0, // 排序

        sysIntegration: "无",
        buildingIntelligent: "无",
        electronicIntelligent: "无",
        tech: "无",
        antiSafe: "无",
        otherSeurity: "涉密系统集成资质",
        otherTwo: "双软企业",
        otherCmmit: "CMMI",
        otherItss: "ITSS",
        otherCs: "CS",
        otherIso: "ISO",
        otherList: [],
      },

      list: [],
      sortBy_list: {},
      page: {
        pageNum: 1,
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    handleSortChange(item) {
      this.sortBy_list = {};
      this.sortBy_list[item.key] = "123";
      this.getList();
    },
    handleChange(item) {
      this.form[item.title] = item.key;
      this.getList();
    },
    handleChangeOther(value) {
      this.form.otherList = value;
      this.getList();
    },
    getList(current) {
      let params = {};
      if (this.form.name) {
        params["name"] = this.form.name;
      }
      if (this.sortBy_list["createTime"]) {
        params["createTime"] = this.sortBy_list["createTime"];
      }
      if (this.sortBy_list["bidAmount"]) {
        params["bidAmount"] = this.sortBy_list["bidAmount"];
      }
      if (this.sortBy_list["salse"]) {
        params["salse"] = this.sortBy_list["salse"];
      }
      if (this.form.sysIntegration != "无") {
        params["sysIntegration"] = this.form.sysIntegration;
      }
      if (this.form.tech != "无") {
        params["tech"] = this.form.tech;
      }
      if (this.form.buildingIntelligent != "无") {
        params["buildingIntelligent"] = this.form.buildingIntelligent;
      }
      if (this.form.electronicIntelligent != "无") {
        params["electronicIntelligent"] = this.form.electronicIntelligent;
      }
      if (this.form.antiSafe != "无") {
        params["antiSafe"] = this.form.antiSafe;
      }
      if (this.form.otherList.includes(0)) {
        params["otherSeurity"] = this.form.otherSeurity;
      }
      if (this.form.otherList.includes(1)) {
        params["otherTwo"] = this.form.otherTwo;
      }
      if (this.form.otherList.includes(2)) {
        params["otherCmmit"] = this.form.otherCmmit;
      }
      if (this.form.otherList.includes(3)) {
        params["otherItss"] = this.form.otherItss;
      }
      if (this.form.otherList.includes(4)) {
        params["otherCs"] = this.form.otherCs;
      }
      if (this.form.otherList.includes(5)) {
        params["otherIso"] = this.form.otherIso;
      }
      params["pageNum"] = current ? current : this.page.pageNum;
      this.$axios
        .get("/member/enterpriseSearch", {
          params,
        })
        .then((res) => {
          if (res.state == 200) {
            this.form.name = "";
            this.list = res.data.list;
            this.page.pageNum = res.data.pageNum;
            this.page.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.safety-wraper {
  width: $safety-width;
  margin: 0 auto;

  .search-filter-group {
    margin-top: 64px;
    border: 1px solid #ededed;
    padding: 0 26px;
    .search-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ededed;
      height: 89px;
      .search-sort {
        display: flex;
        align-items: center;
      }
      .search-input-group {
        input {
          width: 184px;
          height: 34px;
          line-height: 34px;
          background: #ffffff;
          border: 1px solid #999999;
          outline: none;
          font-size: 16px;
        }
        button {
          width: 151px;
          // height: 34px;

          background: #3883ff;
          border: 1px solid #3883ff;
          outline: none;
          margin-left: 8px;

          color: #ffffff;
          line-height: 34px;
          letter-spacing: 1px;
          font-size: 18px;
          &:active {
            background: #296ad6;
          }
        }
      }
    }
    .search-filter-content {
      .search-filter-item {
        display: flex;
        align-items: center;
        margin: 20px 0;
        .filter-label {
        }
        .filter-selector {
        }
      }
      .mutiply-select-row {
        border-top: 1px solid #ededed;
        height: 80px;
      }
    }
  }

  .search-result-group {
    margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
    .search-result-item {
      display: flex;
      width: 586px;

      border: 1px solid #efefef;
      padding: 16px;
      box-sizing: border-box;

      margin-bottom: 27px;
      &:nth-child(odd) {
        margin-right: 27px;
      }
      .search-result-image {
        width: 192px;
        height: 192px;
        background-color: #ddd;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
      }
      .search-result-content {
        margin-left: 59px;
        line-height: 30px;
        .search-result-title {
          color: #333333;
          font-size: 22px;
          margin-bottom: 53px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 25px;
            width: 29px;
            height: 2px;
            background-color: #3883ff;
          }
        }

        .search-result-count {
          color: #333333;
          font-size: 16px;
        }
        .search-result-sales {
          color: #333333;
          font-size: 16px;
        }
      }
    }
  }
  .pagination {
    text-align: right;
    margin-bottom: 114px;
  }
}
</style>